document.addEventListener("turbolinks:load", () => {
  function getLocation(setCity = "") {
    const id = document.querySelector("#profile_state_id").value;
    fetch(`/cities_by_state?state_id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        let citiesSelect = document.getElementById("cities_select");
        citiesSelect.innerHTML = "";
        data.forEach((city) => {
          let option = document.createElement("option");
          option.text = city.name;
          option.value = city.id;
          citiesSelect.add(option);

          if (setCity && setCity === city.name) {
            document.querySelector("#cities_select").value = city.id;
          }
        });
      });
  }
  const profileState = document.querySelector("#profile_state_id");
  if (profileState) {
    profileState.addEventListener("change", () => {
      console.log("changed")
      getLocation();
    });
  }
});
