import React, { useEffect, useRef, useState } from "react";
import "rc-slider/assets/index.css";

export function DimensionProfile(props) {
  const [profileFields, setProfileFields] = useState([]);
  const [dimensionProfiles, setDimensionProfiles] = useState([]);

  useEffect(() => {
    fetchDimension();

    return () => {
      debouncedUpdateSurvey(true);
    };
  }, []);

  const fetchDimension = async () => {
    const url = `/api/v1/dimensions/${
      props.id ? props.id : null
    }/edit?company_id=${props.companyId}`;
    const response = await axios.get(url);
    setProfileFields(response.data.profile_fields);
    if (response.data.dimension)
      setDimensionProfiles(response.data.dimension.dimension_profiles);
  };

  const addDimensionProfile = () => {
    setDimensionProfiles([
      ...dimensionProfiles,
      {
        profile_field_id: profileFields[0].id,
        profile_field_values: [],
      },
    ]);
  };

  const handleDimensionProfileProfileFieldChange = (profileFieldId, index) => {
    const selectedProfileField = profileFields.find((pf) => Number(pf.id) === Number(profileFieldId));

    const currentDimensionProfiles = [...dimensionProfiles];

    currentDimensionProfiles[index].profile_field_id = selectedProfileField.id;
    currentDimensionProfiles[index].profile_field_values = [];

    setDimensionProfiles(currentDimensionProfiles);
  };

  const handleDimensionProfileValueChange = (value, dimensionProfileIndex, dimensionProfileValueIndex) => {
    const currentDimensionProfiles = [...dimensionProfiles];

    currentDimensionProfiles[dimensionProfileIndex].profile_field_values[dimensionProfileValueIndex] = value;

    setDimensionProfiles(currentDimensionProfiles);
  };

  const addDimensionProfileValue = (dimensionProfileIndex) => {
    const currentDimensionProfiles = [...dimensionProfiles];

    currentDimensionProfiles[dimensionProfileIndex].profile_field_values = [
      ...currentDimensionProfiles[dimensionProfileIndex].profile_field_values,
      profileFields.find(
        (pf) => pf.id === dimensionProfiles[dimensionProfileIndex].profile_field_id
      ).values[0],
    ];

    setDimensionProfiles(currentDimensionProfiles);
  };

  const removeDimensionProfileValue = (dimensionProfileIndex, dimensionProfileValueIndex) => {
    const currentDimensionProfiles = [...dimensionProfiles];

    currentDimensionProfiles[dimensionProfileIndex].profile_field_values.splice(
      dimensionProfileValueIndex,
      1
    );

    setDimensionProfiles(currentDimensionProfiles);
  };

  const removeDimensionProfile = (dimensionProfileIndex) => {
    const currentDimensionProfiles = [...dimensionProfiles];

    currentDimensionProfiles.splice(dimensionProfileIndex, 1);

    setDimensionProfiles(currentDimensionProfiles);
  };

  return (
    <div className="">
      <p className="btn btn-primary w-100" onClick={addDimensionProfile}>
        <i className="fa fa-plus"></i>
        <br />
        Adicionar filtro
      </p>

      {dimensionProfiles.map((dimensionProfile, dimensionProfileIndex) => {
        return (
          <div className="bg-white p-5 br-8 mb-4">
            <input
              type="hidden"
              name="dimension_profiles[][id]"
              key={dimensionProfileIndex}
              value={dimensionProfile.id}
            />
            <select
              name="dimension_profiles[][profile_field_id]"
              value={dimensionProfile.profile_field_id}
              onChange={(e) =>
                handleDimensionProfileProfileFieldChange(e.target.value, dimensionProfileIndex)
              }
              placeholder="Campo do perfil"
              className="form-control mb-2 border-primary"
            >
              {profileFields && profileFields.length > 0 && profileFields.map((profileField) => {
                return (
                  <option
                    value={profileField.id}
                    key={profileField.id}
                  >
                    {profileField.name}
                  </option>
                );
              })}
            </select>
            {dimensionProfile.profile_field_values.map(
              (dimension_profile_value, dimensionProfileValueIndex) => {
                return (
                  <div className="flex align-items-center justify-content-around gap-24">
                    <p className="w-20">Opção de valor:</p>
                    <select
                      value={dimension_profile_value}
                      onChange={(e) =>
                        handleDimensionProfileValueChange(
                          e.target.value,
                          dimensionProfileIndex,
                          dimensionProfileValueIndex,
                        )
                      }
                      name="dimension_profiles[][profile_field_values][]"
                      placeholder="Valor do campo do perfil"
                      className="form-control mb-2"
                    >
                      {profileFields
                        .find((pf) => pf.id === dimensionProfile.profile_field_id)
                        .values.map((value) => {
                          return (
                            <option value={value} key={value}>
                              {value}
                            </option>
                          );
                        })}
                    </select>
                    <p
                      className="btn btn-danger px-5"
                      onClick={() =>
                        removeDimensionProfileValue(dimensionProfileIndex, dimensionProfileValueIndex)
                      }
                    >
                      <i className="fa fa-trash"></i>
                    </p>
                  </div>
                );
              }
            )}
            <p
              className="btn btn-primary px-4"
              onClick={() => addDimensionProfileValue(dimensionProfileIndex)}
            >
              <i className="fa fa-plus pr-3"></i>
              Adicionar valor
            </p>
            <p
              className="btn btn-danger px-4"
              onClick={() => removeDimensionProfile(dimensionProfileIndex)}
            >
              <i className="fa fa-trash pr-3"></i>
              Remover filtro
            </p>
          </div>
        );
      })}
    </div>
  );
}
