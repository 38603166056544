document.addEventListener("turbolinks:load", function () {
  const profilesModal = document.querySelector("#modal-profiles");

  if (!profilesModal) return;

  const canEditProfiles = document.querySelector("#can-edit-profiles");

  const caseTableBody = profilesModal.querySelector("tbody");
  const profileLinks = document.querySelectorAll(".profiles-button");
  const modalTitle = profilesModal.querySelector(".modal-title");

  const getModal = async (fieldId, fieldValue, companyId) => {
    caseTableBody.innerHTML = "Carregando informações...";
    const response = await axios.get(
      `/api/v1/profiles?company_id=${companyId}&field_id=${fieldId}&field_value=${fieldValue}`
    );

    caseTableBody.innerHTML = "";
    response.data.users.forEach((user) => {
      const htmlString = `<tr><td>${
        canEditProfiles
          ? `<a href="${user.link}">${user.email}</a>`
          : `<p class="m-0">${user.email}</p>`
      }</td></tr>`;
      caseTableBody.insertAdjacentHTML("beforeend", htmlString);
    })
  };

  profileLinks.forEach((link) => {
    link.addEventListener("click", (e) => {
      const fieldId = e.currentTarget.dataset.fieldId;
      const fieldValue = e.currentTarget.dataset.fieldValue;
      const companyId = e.currentTarget.dataset.companyId;
      const title = e.currentTarget.dataset.title;
      modalTitle.innerHTML = title;
      getModal(fieldId, fieldValue, companyId);
    });
  });
});
