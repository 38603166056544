document.addEventListener("turbolinks:load", () => {
  const generateLinkButton = document.getElementById('generateLinkButton');
  generateLinkButton && generateLinkButton.addEventListener('click', generateLink);
  function generateLink() {
    const linkContainer = document.getElementById('linkContainer');
    const generateLinkPath = linkContainer.dataset.generateLinkPath;
    console.log(generateLinkPath)
    console.log("Current Company Slug:", '<%= current_company.slug %>');
    fetch(generateLinkPath, { method: 'GET' })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro ao gerar o link da pesquisa!');
        }
        return response.json();
      })
      .then(data => {
        if (data.form_url) {
          linkContainer.innerHTML = data.form_url;
          copyToClipboard(data.form_url);
          alert('Link da pesquisa copiado para a área de transferência!');
        } else {
          alert(data.error);
        }
      })
      .catch(error => {
        console.error('Erro ao gerar o link da pesquisa!', error);
      });
  }

  function copyToClipboard(text) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }
});
