import React from "react";
import ReactDOM from "react-dom";

export function ReactPage(props) {
  return (
    <div className="bg-success vh-40 flex around p-5 br-8">
     
    </div>
  );
}
