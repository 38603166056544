import Inputmask from "inputmask";

document.addEventListener("turbolinks:load", () => {
  let cnpj = document.getElementById("company_cnpj");
  if (cnpj !== null) {
    let im = new Inputmask("99.999.999/9999-99");
    im.mask(cnpj);
  }

  let cpf = document.getElementById("user_cpf");
  if (cpf !== null) {
    let im = new Inputmask("999.999.999-99");
    im.mask(cpf);
  }

});
