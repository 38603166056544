import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Select from "react-select";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import Modal from "react-modal";
import Swal from "sweetalert2";

export function TemplateQuestionEditor(props) {
  const [templateQuestion, setTemplateQuestion] = useState([]);
  const [templateOptionFields, setTemplateOptionFields] = useState([]);
  const [dimensions, setDimensions] = useState([]);
  const [lockOptionsEdit, setLockOptionsEdit] = useState(false);

  const questionKinds = [
    { value: "multiple_choice", name: "Múltipla escolha" },
    { value: "open", name: "Pergunta aberta" },
    {
      value: "multiple_choice_with_last_option_open",
      name: "Múltipla escolha ou outro",
    },
    {
      value: "multiple_choice_allow_many",
      name: "Múltipla escolha com seleção múltipla",
    },
    { value: "date_pick", name: "Selecionar uma data" },
    {value: "slider_10", name: "Slider 10"},
    {value: "slider_4", name: "Slider 4"},
  ];

  const respondentTypeOptions =  [
    { value: "all", name: "Todos" },
    { value: "user", name: "Apenas liderado" },
    { value: "leader", name: "Apenas líder" },
  ];

  useEffect(() => {
    fetchTemplateQuestion();
  }, []);

  const fetchTemplateQuestion = async () => {
    const url = `/api/v1/template_questions/${props.id || "null"}?company_id=${props.companyId}`;
    const response = await axios.get(url);

    setTemplateQuestion(response.data.template_question);
    setTemplateOptionFields(response.data.template_option_fields);
    setDimensions(response.data.dimensions);
    if (response.data.template_question.options.length)
      setLockOptionsEdit(true);
  };

  const handleQuestionChange = (field, value) => {
    const obj = { ...templateQuestion };
    obj[field] = value;
    setTemplateQuestion(obj);
  };

  const handleQuestionOptionChange = (value, field, optionOrder) => {
    const currentOptions = [...templateQuestion.options];
    const editedQuestionOption = currentOptions.find(
      (templateQuestion) => templateQuestion.order === optionOrder
    );
    editedQuestionOption[field] = value;
    handleQuestionChange("options", currentOptions);
  };



  const dimensionOptions = () => {
    const uniqueDimensions = dimensions.reduce((accumulator, current) => {
      const uniqueKey = `${current.name}-${current.type}-${current.kind}`;
      const existingDimension = accumulator.find(item => `${item.name}-${item.type}-${item.kind}` === uniqueKey);

      if (!existingDimension) {
        accumulator.push(current);
      } else if (current.parent_dimension && !existingDimension.parent_dimension) {
        accumulator = accumulator.filter(item => `${item.name}-${item.type}-${item.kind}` !== uniqueKey);
        accumulator.push(current);
      } else if (current.id < existingDimension.id) {
        accumulator = accumulator.filter(item => `${item.name}-${item.type}-${item.kind}` !== uniqueKey);
        accumulator.push(current);
      }
      return accumulator;
    }, []);

    return uniqueDimensions.map((dimension) => {
      const parentName = dimension.parent_dimension ? dimension.parent_dimension : '';
      const label = parentName ? `${dimension.name} (${parentName}) | ${dimension.kind == "climate" ? "Clima" : "Desempenho"} | ${dimension.id}` : `${dimension.name} | ${dimension.kind == "climate" ? "Clima" : "Desempenho"} | ${dimension.id}`;
      return { value: dimension.id, label: label };
    });
  };

  const addQuestionOption = (text = "", value = null) => {
    const objectWithNewQuestionOption = [
      ...templateQuestion.options,
      {
        text: text,
        order: templateQuestion.options.length,
        value: value,
        id: templateQuestion.options.length,
        reorder_options: false,
      },
    ];

    handleQuestionChange("options", objectWithNewQuestionOption);
  };

  const populateTemplateOptionFields = (name) => {
    const findTemplateOptionFields = templateOptionFields.find(
      (tof) => tof.name === name
    );

    const currentOptions = [];

    findTemplateOptionFields.options.forEach((option) => {
      currentOptions.push({
        text: option.text,
        order: option.order,
        value: option.value,
        id: option.order,
        reorder_options: false,
      });
    });

    handleQuestionChange("options", currentOptions);
    setLockOptionsEdit(true);
  };

  const clearQuestionOptions = () => {
    handleQuestionChange("options", []);
    setLockOptionsEdit(false);
  };

  return (
    <div>
      {templateQuestion && (
        <div key={templateQuestion.order} className="bg-gs-250 br-8 mb-2">
          <div className="question-content p-2">
            <input
              value={templateQuestion.title}
              onChange={(e) => handleQuestionChange("title", e.target.value)}
              placeholder="Título da pergunta"
              className="form-control mb-2"
              name="template_question[title]"
            ></input>
            <input
              value={templateQuestion.prompt}
              onChange={(e) => handleQuestionChange("prompt", e.target.value)}
              placeholder="Conteúdo da pergunta"
              className="form-control mb-2"
              name="template_question[prompt]"
            ></input>
            <input
              value={templateQuestion.alternative_prompt}
              onChange={(e) => handleQuestionChange("alternative_prompt", e.target.value)}
              placeholder="Prompt Alternativo"
              className="form-control mb-2"
              name="template_question[alternative_prompt]"
            />
            <select
              value={templateQuestion.respondent_type}
              onChange={(e) => handleQuestionChange("respondent_type", e.target.value)}
              placeholder="Respondente"
              className="form-control mb-2"
              name="template_question[respondent_type]"
            >
              {respondentTypeOptions.map((type) => {
                return (
                  <option value={type.value} key={type.value}>
                    {type.name}
                  </option>
                );
              })}
            </select>
            <Select
              options={dimensionOptions()}
              isMulti
              placeholder="Dimensões"
              onChange={(e) =>
                handleQuestionChange(
                  "dimension_ids",
                  e.map((selectedOption) => selectedOption.value)
                )
              }
              value={dimensionOptions().filter((opt) =>
                templateQuestion.dimension_ids.includes(opt.value)
              )}
              className="my-3"
            />

            <div className="flex align-items-center gap-12 my-3">
              <input
                type="checkbox"
                value={templateQuestion.optional}
                checked={templateQuestion.optional}
                name="template_question[optional]"
                onChange={(e) =>
                  handleQuestionChange("optional", !templateQuestion.optional)
                }
              />
              <span className="px-3">Pergunta opcional</span>
            </div>

            {templateQuestion.dimension_ids &&
              templateQuestion.dimension_ids.map((dimensionId) => {
                return (
                  <input
                    type="hidden"
                    name="template_question[dimension_ids][]"
                    key={dimensionId}
                    value={dimensionId}
                  />
                );
              })}
            <select
              value={templateQuestion.kind}
              onChange={(e) => handleQuestionChange("kind", e.target.value)}
              placeholder="Tipo de pergunta"
              className="form-control mb-2"
              name="template_question[kind]"
            >
              {questionKinds.map((kind) => {
                return (
                  <option value={kind.value} key={kind.value}>
                    {kind.name}
                  </option>
                );
              })}
            </select>
            {templateQuestion.kind &&
              templateQuestion.kind.includes("multiple_choice") && (
                <div>
                  <select
                    value={templateQuestion.kind}
                    onChange={(e) =>
                      populateTemplateOptionFields(e.target.value)
                    }
                    placeholder="Popular opções de template"
                    className="form-control mb-2"
                  >
                    <option default>Opções de template</option>;
                    {templateOptionFields.map((optionField) => {
                      return (
                        <option value={optionField.name} key={optionField.name}>
                          {optionField.name}
                        </option>
                      );
                    })}
                  </select>
                  {templateQuestion.options.length ? (
                    <div>
                      <div className="mb-3">
                        {templateQuestion.options.map((option) => {
                          return (
                            <div
                              className="px-3 py-1 bg-gs-210 mb-1 flex align-items-center gap-24"
                              key={option.order}
                            >
                              <p className="m-0 mr-3">{option.order}</p>
                              <input
                                value={option.text}
                                name="template_question[options][][text]"
                                readOnly={lockOptionsEdit}
                                onChange={(e) =>
                                  handleQuestionOptionChange(
                                    e.target.value,
                                    "text",
                                    option.order
                                  )
                                }
                                placeholder="Texto da opção"
                                className="form-control m-0"
                              ></input>
                              <input
                                value={option.value}
                                type="number"
                                name="template_question[options][][value]"
                                readOnly={lockOptionsEdit}
                                onChange={(e) =>
                                  handleQuestionOptionChange(
                                    e.target.value,
                                    "value",
                                    option.order
                                  )
                                }
                                placeholder="Valor da opção"
                                className="form-control m-0 f-20"
                              ></input>
                              <input
                                type="hidden"
                                name="template_question[options][][order]"
                                value={option.order}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <p>Nenhuma opção até o momento</p>
                  )}
                  <div className="flex gap-24">
                    {!lockOptionsEdit && (
                      <p
                        className="btn btn-primary"
                        onClick={() =>
                          addQuestionOption(templateQuestion.order)
                        }
                      >
                        <i className="fa fa-plus pr-3"></i>Adicionar opção de
                        resposta
                      </p>
                    )}
                    <p
                      className="btn btn-primary"
                      onClick={() =>
                        clearQuestionOptions(templateQuestion.order)
                      }
                    >
                      <i className="fa fa-trash pr-3"></i>Limpar opções de
                      resposta
                    </p>
                  </div>
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
}
