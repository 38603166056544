import React, { useEffect, useState } from "react";
import "rc-slider/assets/index.css";

export function ProfileField(props) {
  const [profileField, setProfileField] = useState();

  useEffect(() => {
    fetchProfileField();
  }, []);

  const fetchProfileField = async () => {
    const url = `/api/v1/profile_fields/${
      props.id ? props.id : null
    }/edit?company_id=${props.companyId}`;
    const response = await axios.get(url);
    setProfileField(response.data);
  };

  const addPossibleValue = () => {
    setProfileField({
      ...profileField,
      possible_values: [...profileField.possible_values, ""],
    });
  };

  const handlePossibleValueChange = (value, index) => {
    const currentProfileField = { ...profileField };

    currentProfileField.possible_values[index] = value;

    setProfileField(currentProfileField);
  };

  const removePossibleValue = (index) => {
    const currentProfileField = { ...profileField };

    currentProfileField.possible_values = currentProfileField.possible_values.filter((_, i) => i !== index);

    setProfileField(currentProfileField);
  };

  return (
    <div className="">
      <h3 className="my-4">Opções de valor</h3>
      <p className="btn btn-primary w-25" onClick={addPossibleValue}>
        <i className="fa fa-plus"></i>
        <br />
        Adicionar opção de valor
      </p>

      {profileField && profileField.possible_values &&
        profileField.possible_values.map((value, index) => {
          return (
            <div className="px-2 br-8 mb-4">
              <div className="flex align-items-center justify-content-around gap-24">
                <p className="w-20">Opção de valor:</p>
                <input
                  value={value}
                  onChange={(e) =>
                    handlePossibleValueChange(e.target.value, index)
                  }
                  name="profile_field[possible_values][]"
                  placeholder="Valor do campo do perfil"
                  className="form-control mb-2"
                ></input>
                <p
                  className="btn btn-danger px-5"
                  onClick={() =>
                    removePossibleValue(index)
                  }
                >
                  <i className="fa fa-trash"></i>
                </p>
              </div>
            </div>
          );
        })}
    </div>
  );
}
