import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import Rainbow from "rainbowvis.js"

import ReactApexChart from "react-apexcharts";

export function HorizontalStackedBars(props) {
  const calculateColors = () => {
    if (props.data.length === 3) {
      return ["#28A745", "#FFC107", "#DC3545"];
    } else if (props.data.length === 2) {
      return ["#28A745", "#DC3545"];
    } else if (props.data.length === 5) {
      return ["#DC3545", "#F7BB00", "#F7F700", "#58F700", "#28A745"];
    } else {
      const colorArray = [
        "#7309AC",
        "#009999",
        "#CD0274",
        "#1240AC",
        "#FDAB00",
        "#FE0000",
        "#FEDC33",
      ];
      return [...colorArray, ...colorArray, ...colorArray].slice(
        0,
        props.data.length
      );
    }
  };

  const calculateHeight = () => {
    return props.data[0].data.length * 40 > 200
      ? props.data[0].data.length * 40
      : 300;
  };

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 600,
        animateGradually: {
          enabled: true,
          delay: 100,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 800,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    title: {
      text: undefined,
      display: false,
    },
    xaxis: {
      categories: props.labels,
      max: props.data.every((item) => item.data.every((subItem) => subItem <= 4)) ? 4 : (props.data.every((item) => item.data.every((subItem) => subItem <= 10)) ? 10 : 100),
      labels: {
        formatter: function (val) {
          return val.toFixed(1) + (props.data.every((item) => item.data.every((subItem) => subItem <= 10)) ? '' : "%")
        },
      },
    },
    yaxis: {
      labels: {
        maxWidth: 320,
      },
      title: {
        text: undefined,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "%";
        },
      },
    },
    fill: {
      opacity: 1,
      colors: calculateColors(),
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: calculateColors(),
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={props.data}
        type="bar"
        height={calculateHeight()}
        colors={"red", "black"}
      />
    </div>
  );
}
