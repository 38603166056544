import React, { useEffect, useState } from "react";
import "rc-slider/assets/index.css";

export function SurveyProfile(props) {
  const [profileFields, setProfileFields] = useState([]);
  const [onboarding, setOnboarding] = useState("private");
  const [surveyProfileFields, setSurveyProfileFields] = useState([]);

  useEffect(() => {
    fetchSurvey();
  }, []);

  const fetchSurvey = async () => {
    const url = `/api/v1/surveys/${
      props.id ? props.id : null
    }/edit?company_id=${props.companyId}`;
    const response = await axios.get(url);
    setProfileFields(response.data.profile_fields);
    setOnboarding(response.data.survey ? response.data.survey.onboarding : "private");
    if (response.data.survey_profile_fields)
      setSurveyProfileFields(response.data.survey_profile_fields);
  };

  const addSurveyProfileField = () => {
    setSurveyProfileFields([
      ...surveyProfileFields,
      {
        id: null,
        profile_field_id: profileFields[0].id,
      },
    ]);
  };

  const handleFilterProfileFieldChange = (value, index) => {
    const currentFilters = [...surveyProfileFields];

    currentFilters[index].profile_field_id = value;

    setSurveyProfileFields(currentFilters);
  };

  const removeFilter = (filterIndex) => {
    const currentFilters = [...surveyProfileFields];

    currentFilters.splice(filterIndex, 1);

    setSurveyProfileFields(currentFilters);
  };

  return (
    <div className="">
      <h3>Tipo de entrada</h3>
      <ul>
        <li>
          <strong>Entrada privada: </strong>Apenas usuários convidados para a
          pesquisa poderão responder a pesquisa. Informações de perfil dos
          usuários devem ser preenchidas antes de lançar a pesquisa.
        </li>
        <li>
          <strong>Entrada pública: </strong>Usuários da empresa podem entrar e
          realizar a pesquisa através de um link, e serão automaticamente
          conectados a empresa. As informações de perfil são preenchidas pelo
          usuário ao começar a pesquisa.
        </li>
        <li>
          <strong>Entrada aberta: </strong>Qualquer pessoa pode responder a
          pesquisa a partir do link e não serão conectados para a sua empresa.
        </li>
      </ul>
      <div className="d-flex align-items-center">
        <p
          className={`btn ${
            onboarding === "private" ? "btn-primary" : "btn-secondary"
          }`}
          onClick={() => setOnboarding("private")}
        >
          Privada
        </p>
        <p
          className={`btn ${
            onboarding === "public" ? "btn-primary" : "btn-secondary"
          }`}
          onClick={() => setOnboarding("public")}
        >
          Pública
        </p>
        <p
          className={`btn ${
            onboarding === "open" ? "btn-primary" : "btn-secondary"
          }`}
          onClick={() => setOnboarding("open")}
        >
          Aberta
        </p>
      </div>
      <input
        value={onboarding}
        name="survey[onboarding]"
        className="form-control mb-2 d-none"
      ></input>

      {onboarding !== "private" && (
        <>
          <h3 className="my-4">Características de perfil</h3>
          <p class="info-text br-8 px-4 mb-3">
            <i class="fa fa-info-circle px-3"></i>
            Selecione abaixo quais características de perfil o usuário terá que responder para entrar na pesquisa. As opções que serão dadas ao usuário são as opções de valores definidas no Campo de Perfil cadastradas no menu "Perfis".
          </p>
          <p className="btn btn-primary w-25" onClick={addSurveyProfileField}>
            <i className="fa fa-plus"></i>
            <br />
            Adicionar característica de perfil
          </p>

          {surveyProfileFields.map((filter, filterIndex) => {
            return (
              <div className="bg-white px-2 br-8 mb-4 d-flex align-items-center">
                <input
                  type="hidden"
                  name="survey_profile_fields[][id]"
                  key={filterIndex}
                  value={filter.id}
                />
                <select
                  name="survey_profile_fields[][profile_field_id]"
                  value={filter.profile_field_id}
                  onChange={(e) =>
                    handleFilterProfileFieldChange(e.target.value, filterIndex)
                  }
                  placeholder="Campo do perfil"
                  className="form-control mb-2 border-primary"
                >
                  {profileFields.map((profileField) => {
                    return (
                      <option value={profileField.id} key={profileField.id}>
                        {profileField.name}
                      </option>
                    );
                  })}
                </select>
                <p
                  className="btn btn-danger m-0 ml-3"
                  onClick={() => removeFilter(filterIndex)}
                >
                  <i className="fa fa-trash pr-3"></i>Remover
                </p>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}
